<template>
	<div class="check-piece">
		<!-- 重新定型 -->
		<el-input placeholder="请输入后八位底盘号" v-model="inputVal" class="input-search">
			<el-button slot="append" @click="definitiveClick">重新定型</el-button>
		</el-input>
		<!-- 基本信息 -->
		<div class="check-wrapper basic-info">
			<div class="title">基本信息</div>
			<div class="main-info">
				<div class="info-item w100">
					<span>车型：</span><em>{{getField(productinfo, 'vProductText')}}</em>
				</div>
				<div class="info-item w100">
					<span>下线日期：</span><em>{{getField(vininfo, 'dofflinedate')}}</em>
				</div>
				<div class="info-item w100">
					<span>排放标准：</span><em>{{getField(productinfo, 'vemissiontypetext')}}</em>
				</div>
				<div class="info-item w100">
					<span>动力类型：</span><em>{{getField(productinfo, 'voiltypetext')}}</em>
				</div>
				<div class="info-item w100">
					<span>车型颜色：</span><em>{{getField(productinfo, 'vcolortext')}}</em>
				</div>
				<div class="info-item w100">
					<span>发动机型号：</span><em>{{getField(productinfo, 'venginetypetext')}}</em>
				</div>
			</div>
		</div>
		<!-- 配置参数 -->
		<div class="check-wrapper config">
			<div class="title">配置参数</div>
			<div class="config-con">
				{{getField(productinfo, 'vcabtypeconfigtext')}}
                {{getField(productinfo, 'VOTHERS')}}
			</div>
			<el-button type="primary" class="btn" @click="viewModel">查看该车型备品</el-button>
		</div>
	</div>
</template>

<script>
    import * as QywxApi from '@/api/qywx'
    import {initSdk} from "wecom-sidebar-jssdk";
    import config from "../config";
	export default {
		data() {
			return {
				inputVal: '',
                fullVin: '',
                productinfo: [],
                vininfo: []
			}
		},
    created() {
    },
    methods: {
			//查看该车型备品
			viewModel(){
				this.$router.push('/qywx-bd/checkResult?vin=' + this.fullVin)
			},
			// 重新定型
			definitiveClick() {
                this.inputVal = this.inputVal.toUpperCase()
				if (this.inputVal && this.inputVal.length >= 8) {
                    QywxApi.getCatalogVinInfo(this.inputVal).then(res => {
                        console.log(res)
                        let data = this.formatData(res.data.data)
                        console.log('data:', data)
                        this.productinfo = data.productinfo
                        this.vininfo = data.vininfo
                    })
                } else {
					this.$toast.fail('请输入后八位底盘号！');
				}
			},
            formatData(data) {
                let vininfo = data.vininfo
                let productinfo = data.productinfo
                let productCode = ''
                for(let i = 2; i < vininfo.length; i++) {
                    if (vininfo[i].length != vininfo[1].length) {
                        vininfo.splice(i, 1)
                        i--
                        continue
                    }
                    if (vininfo[i][this.gi(vininfo, 'vvin')].indexOf(this.inputVal) > -1) {
                        this.fullVin = vininfo[i][this.gi(vininfo, 'vvin')]
                        productCode = vininfo[i][this.gi(vininfo, 'vproductcode')]
                        break
                    }else{
                        vininfo.splice(i, 1)
                        i--
                    }
                }
                for(let i=2;i<productinfo.length; i++){
                    if(productinfo[i].length != productinfo[1].length){
                        productinfo.splice(i, 1)
                        i--
                        continue
                    }
                    if(productinfo[i][this.gi(productinfo, 'vproductcode')] != productCode){
                        productinfo.splice(i, 1)
                        i--
                    }
                }
                if(vininfo.length < 3 || productinfo.length < 3) {
                    this.$toast.fail('未查询到该车型！');
                }
                return data
            },
            gi(list, field) {
                if(!list || list.length < 2) {
                    return 0
                }
                return list[1].findIndex(item => item === field)
            },
            getField(list, field) {
                if(!list || list.length < 2) {
                    return ''
                }
                return list[2][this.gi(list, field)]
            }
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/css/wecom.scss";
</style>
